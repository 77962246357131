<template>
  <div class="ly_news_cb_main">
      <div class="ly_news_cb_title">菌种代保藏</div>
      <p>注：以下带*为硬性要求，其余项为弹性要求 </p>
      <div class="ly_news_cb_hot_so">1、基本要求</div>
      <p>（1）提交菌种的实物和信息</p>

      <p>（2）实物与信息要一致</p>

      <p>（3）菌种应经过分子生物学鉴定，分类地位鉴定至属水平</p>

      <div class="ly_news_cb_hot_so">2、具体要求</div>
      <p>（1）提交菌种实物*</p>
      <p>（2）生长良好的菌种斜面2支，避免提交单菌落</p>
      <p>（3）试管斜面应为纯菌种，无污染，为1周内新鲜培养斜面</p>
      <p>（4）试管上部注明原始编号与接种日期（年月日），标识字迹清楚，原始编号应与信息登记表中原始编号一致</p>
      <p>（5）厌氧菌请提交合适的保藏备份，保证菌种的存活性</p>
      <div class="ly_news_cb_hot_so">3、提交菌种信息</div>
      <p>（1）基本信息*<a href="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/054609394064c92ef3d7b7670dbb92ff.doc"  rel="nofollow">《菌种信息登记表》</a></p>
      <p>（2）寄送样本到本公司</p>
      <p>（3）菌种信息登记表：菌种提交人按照附件2要求如实填写，提交格式为word文件，文件名为“菌种保藏登记信息表-原始编号”</p>
      <div class="ly_news_cb_hot_so">4、鉴定信息</div>
      <p>（1）分子生物学信息*：提交该菌株的序列信息，如16S rDNA、ITS序列、26S序列及看家基因等序列，并将该序列上传至“Genbank”，提供Genbank注册号，并在<a href="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/054609394064c92ef3d7b7670dbb92ff.doc"  rel="nofollow">《菌种信息登记表》</a>“特征特性”栏目中注明。特殊情况可提交word格式文件，在文档中注明序列名称，文件名为“分子生物学信息-原始编号”。</p>

      <p>（2）生理生化信息：即该菌株的生理生化信息，提交格式为word文件，文件名为“生理生化信息-原始编号”。</p>
      <div class="ly_news_cb_hot_so">5、菌落形态照片及描述</div>
      <p>（1）菌落形态照片：为含有整个平板（平板规格为Φ90mm）的完整照片，而非平板上部分菌落照片，提交格式为JPG格式，照片像素不低于1024×768，文件名为“菌落照片-原始编号”。</p>
      <p>（2）菌落形态描述*：应写明提供的菌落照片是何种培养条件(培养基种类、培养温度、培养时间、是否厌氧)下获得的，描述包括菌落颜色、形状、质地、大小等能够反映该菌株特征特性的内容，应按一定的顺序、尽可能详细的描述，提交格式为word文件，文件名为“菌落描述-原始编号”。</p>

      <div class="ly_news_cb_hot_so">6、显微形态照片及描述</div>
      <p>（1）显微形态照片：像素大于或等于1024×768，细菌或酵母应有清晰的单个细胞，霉菌应有分生孢子结构产生的特征性图。提交格式为JPG格式，文件名为“显微照片-原始编号”。</p>
      <p>（2）显微形态描述*：应有特征细胞或结构的形状、大小尺寸等描述，应按一定的顺序、尽可能详细的描述，提交格式为word文件，文件名为“显微描述-原始编号”。</p>
      <div class="ly_news_cb_hot_so">7、其它</div>
      <p>（1）可以体现该菌种性能的图片也可以提交，但需有对应的word文档说明</p>
      <p>（2）请在附件<a href="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/054609394064c92ef3d7b7670dbb92ff.doc"  rel="nofollow">《微生物保藏信息登记表》</a>中填写代保藏样本信息、并发送给业务人员进行确认；</p>
      <p>（3）请在附件<a href="https://beinabncc.oss-cn-beijing.aliyuncs.com/News/a4f09958ae467e77c00decb3f30e7373.doc"  rel="nofollow">《微生物委托寄存协议》</a>中签订微生物寄存协议，并发送给业务人员进行确认。</p>
      <p>（4）菌种代保藏需5至10个工作日。</p>
  </div>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/099be4cda651b04a92a215ad1e6edd8d8/1"
        >
          <!-- <img src="https://www.bzwz.com/static/m/images/icon52.png" alt="" /> -->
          <img src="@/assets/img/common/icon52.png" alt="" />
          客服
        </a>
      </li>

      <li class="footer-li button download" @click="onBack()" style="background-color: #df0024">
        <a href="javascript:;"> 返回 </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
import {
  defineComponent
} from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "identiFication",
  setup() {
    const router = useRouter();
    function onBack() {
      router.back()
    }
    return {
      onBack
    };
  },
});
</script>


<style lang="scss" scoped>
.ly_news_cb_main {
  padding: 15px;
  font-size: 0.42667rem;
  line-height: 0.66667rem;
  color: #666;
  padding-bottom: 1.33333rem;
}
img{
    width:100%;
}
a{
    color:#05b3d2;
}
p {
  margin: 1em 0;
  word-break: break-word;
  text-indent: 20px;
}

.ly_news_cb_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #444;
}

.ly_news_cb_hot_so {
  font-weight: 600;
  color: #444;
  font-size: 18px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 19.5px;
  height: 19.5px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #df0024;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 10px;
}
footer .button a {
  color: #fff;
}
.download {
  float: right;
}
</style>